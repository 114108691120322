export const aryOne = (ary, func, prop) => {
  const target = ary.filter(func);

  if (target.length === 0) {
    return null;
  } else {
    if (prop) {
      return prop(target[0]);
    } else {
      return target[0];
    }
  }
};
