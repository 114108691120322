
import React from "react";

import { useEffect, useRef } from "react";

const AppContainer = (prop) => {

  useEffect(() => {
    prop.history.push("/inside/entries");
  }, []);

  
  return (
    <>
    </>
  );
};
export default AppContainer;
