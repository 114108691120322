import React, { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import App from "views_out_page/auth";
import { initReactI18next, useTranslation } from "react-i18next";
import { post_auth } from "stores_api/auth";
import NotificationAlert from "react-notification-alert";
import notification from "views_in_page/Basic/notification";

const AppContainer = (prop) => {

  const dispatch = useDispatch();
  const ref = useRef();

  const [t, i18n] = useTranslation();
  const auth = useSelector((state) => state.auth);

  const toast = (inMessage) => {
    notification(ref.current, inMessage);
  };

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={ref} />
      </div>
      <App
        t={t}
        auth={auth}
        on_post_auth={(data) =>
          dispatch(
            post_auth({
              data,
              toast,
              page: () => {
                prop.history.push("/");
              },
            })
          )
        }
      />
    </>
  );
};
export default AppContainer;
