import React, { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import App from "views_in_page/entries";

import NotificationAlert from "react-notification-alert";

import { get_entries } from "stores_api/entries";
import { put_entries } from "stores_api/entries";

import { initReactI18next, useTranslation } from "react-i18next";
import notification from "views_in_page/Basic/notification";


const AppContainer = ({ history }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  useEffect(() => {}, []);

  const toast = (inMessage) => {
    notification(ref.current, inMessage);
  };

  const entries = useSelector((state) => state.entries);
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={ref} />
      </div>
      <App
        t={t}
        on_get_entries={({ data, page }) =>
          dispatch(
            get_entries({
              data,
              toast,
              page,
            })
          )
        }
        entries={entries}
        on_put_entries={({ data, page, search }) =>
        dispatch(
          put_entries({
            data,
            toast,
            page,
            search,
          })
        )
      }



      />
    </>
  );
};
export default AppContainer;
