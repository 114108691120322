import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import App from "views_in_page/staffs";

import NotificationAlert from "react-notification-alert";
import notification from "views_in_page/Basic/notification";

import { get_staffs } from "stores_api/staffs";
import { post_staffs } from "stores_api/staffs";
import { put_staffs } from "stores_api/staffs";
import { delete_staffs } from "stores_api/staffs";

import { initReactI18next, useTranslation } from "react-i18next";
import socketIOClient from "socket.io-client";

// let socket = null;
// alert("null");


const AppContainer = ({ history }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const [conn, setconn] = useState(false);
  const [disconn, setdisconn] = useState(false);

  const [response, setResponse] = useState("");


  useEffect(() => {
    // const endpoint = "http://localhost:50000";
    // const endpoint = "ws://localhost:50000"
    // socket = socketIOClient(endpoint);

    // socket = socketIOClient(endpoint, {
    //     extraHeaders: {
    //       Authorization: "Bearer authorization_token_here"
    //     }
    //   });

    // socket = socketIOClient(endpoint, {
    //   transports: ['polling'],
    // });

    // socket = socketIOClient(endpoint, {
    //   transports: ["polling"],
    //   query: {
    //     token: "cde",
    //   },

    // transportOptions: {
    //   polling: {
    //     extraHeaders: {
    //       "AccessToken": "dfasdf",
    //     },
    //   },
    // },

    // socket = socketIOClient(endpoint, {
      // transports: ["polling"],
      // autoConnect: true,
    // });

    // socket.on(eventName, callback)

    // socket.on("connect", () => {
    //   setconn(socket.connected);
    //   setdisconn(socket.disconnected);
    //   alert("connect");
    // });

    // socket.on("disconnect", () => {
    //   alert("disconnect");
    //   socket.io.opts.query = {
    //     token: "disconnect",
    //   };
    //   socket.open();
    // });

    // socket.on("reconnect_attempt", () => {
    //   socket.io.opts.query = {
    //     token: "fgh",
    //   };
    // });

    // socket.on("staffs", (data) => {
    //   setResponse(data);
    //   alert("staffs");
    // });

    // return () => {
    //   socket.disconnect();
    // };
  }, []);

  const toast = (inMessage) => {
    notification(ref.current, inMessage);
  };

  const staffs = useSelector((state) => state.staffs);
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={ref} />
      </div>
      <App
        t={t}
        on_get_staffs={({ data, page }) =>
          dispatch(
            get_staffs({
              data,
              toast,
              page,
            })
          )
        }
        on_post_staffs={({ data, page }) =>
          dispatch(
            post_staffs({
              data,
              toast,
              page,
            })
          )
        }
        on_put_staffs={({ data, page }) =>
          dispatch(
            put_staffs({
              data,
              toast,
              page,
            })
          )
        }
        on_delete_staffs={({ data, page }) =>
          dispatch(
            delete_staffs({
              data,
              toast,
              page,
            })
          )
        }
        staffs={staffs}
      />
    </>
  );
};
export default AppContainer;
