import React from "react";
import ReactTable from "react-table";
import moment from "moment/moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import ReactDatetime from "react-datetime";
import defaultImage from "assets/img/image_placeholder.jpg";
import classnames from "classnames";

import validator from "validator";
import TablePagination from "@material-ui/core/TablePagination";
import { stateFromHTML } from "draft-js-import-html";
import {
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import Loader from "views_in_page/Basic/loader";
import { aryOne } from "views_in_page/Basic/util";
import ModalEntries from "views_in_page/Entries/ModalEntries";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";



export const schparam = (that) => {
  const upd = {
    // month: moment.isMoment(that.state.sch_month)
    //   ? that.state.sch_month.format()
    //   : null,
    // date: moment.isMoment(that.state.sch_date)
    //   ? that.state.sch_date.format()
    //   : null,
    // original_id: that.state.sch_original_id,
    // block_hash: that.state.sch_block_hash,
    // limit: that.state.sch_limit,
    // page: that.state.sch_page + 1,
    // // status: that.state.option_status ? that.state.option_status.value : null,
    // finish: that.state.option_finish ? that.state.option_finish.value : null,
  };
  return upd;
};

export const param = (that) => {
  // const content = draftToHtml(
  //   convertToRaw(that.state.editorState.getCurrentContent())
  // );

  const upd = {
    entry_id: that.state.entry_id,
    title: that.state.title,
    // content: content,
    content: that.state.html,
    post_date: moment.isMoment(that.state.post_date)
      ? that.state.post_date.format()
      : null,
    display_flg: true,
  };
  return upd;
};

class App extends React.Component {
  constructor(props) {
    super(props);

    const m = moment();

    this.state = {
      alert: null,
      // month: moment([m.year(), m.month(), m.date(), 0, 0, 0, 0]),
      // sch_date: moment([m.year(), m.month(), m.date() + 1, 0, 0, 0, 0]),
      modal: false,
      sch_page: 0,
      sch_limit: 10,
      option_finish: { value: 0, label: "unfinished" },
      editorState: EditorState.createEmpty(),
    };

    this.bindRow = this.bindRow.bind(this);
  }

  warningWithConfirmMessage = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Retry？"
          onConfirm={() => this.execute()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Retry"
          cancelBtnText="Cancel"
          showCancel
        >
          it takes a few minutes
        </ReactBSAlert>
      ),
    });
  };

  bindRow = (prop) => {

    // let editorState_first = EditorState.createEmpty();

    // draftjs-to-html works for only the HTML generated by wysiwyg itself.

    // let editorState = EditorState.createEmpty();
    let html = "";

    if (prop) {
      html = prop.content;
      // const contentBlock = htmlToDraft(html);
      // const contentState = ContentState.createFromBlockArray(
        // contentBlock.contentBlocks
      // )
      // ;
      // editorState = EditorState.createWithContent(contentState);
    }

    // let editorState = stateFromHTML(html);

    this.setState({
      // content: prop ? prop.content : "",
      entry_id: prop ? prop.entry_id : "",
      post_date: prop ? moment(prop.post_date) : "",
      title: prop ? prop.title : "",
      // editorState,
      html
    });

    // let editorState_first = EditorState.createEmpty();

    // const html = that.state.content;
    // if (html) {
    // const contentBlock = htmlToDraft(html);
    // const contentState = ContentState.createFromBlockArray(
    //     contentBlock.contentBlocks
    //   );
    //   editorState_first = EditorState.createWithContent(contentState);
    // }
  };

  execute = () => {
    this.props.on_put_entries({
      data: param(this),
      page: () => {
        this.setState({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Completed"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="info"
            >
              Retry Application completed
            </ReactBSAlert>
          ),
        });
      },
      search: schparam(this),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleChangeSearch = () => {
    this.props.on_get_entries({
      data: {
        ...schparam(this),
        sch_page: 0,
      },
    });
    this.setState({ sch_page: 0 });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ sch_page: newPage });
    this.props.on_get_entries({
      data: {
        ...schparam(this),
        page: newPage + 1,
      },
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ sch_limit: parseInt(event.target.value, 10) });
    this.setState({ sch_page: 0 });
    this.props.on_get_entries({
      data: {
        ...schparam(this),
        limit: parseInt(event.target.value, 10),
        page: 0 + 1,
      },
    });
  };

  componentDidMount() {
    this.props.on_get_entries({
      data: {
        ...schparam(this),
      },
    });
  }

  render() {
    const t = this.props.t;

    const entries = this.props.entries.isFetching
      ? []
      : this.props.entries.entries;

    const total_amount = this.props.entries.isFetching
      ? 0
      : this.props.entries.total_amount;

    const count = this.props.entries.isFetching ? 0 : this.props.entries.count;

    const inLoading =
      this.props.entries.isFetching || this.props.entries.isLoading;

    const options_status = [
      { value: 0, label: "waiting" },
      { value: 1, label: "processing" },
      { value: 2, label: "finished" },
      { value: 3, label: "not related" },
      { value: null, label: "All" },
    ];

    const options_error_code = [
      { value: 0, label: "" },
      { value: 1, label: "block" },
      { value: 2, label: "transaction" },
    ];

    const options_finish = [
      { value: 0, label: "unfinished" },
      { value: 1, label: "finished" },
    ];

    return (
      <>
        <div className="content">
          {this.state.alert}
          <ModalEntries that={this} />
          <div className="info info-horizontal ml-4">
            {/* <div className="icon icon-info">
              <i aria-hidden={true} className="fas fa-splotch" />
            </div> */}
            <div className="description">
              <h4 className="info-title">
                <img
                  alt="..."
                  src={require("assets/img/picture5.png")}
                  width="30px"
                />{" "}
                {t("投稿一覧")}
              </h4>
              <p className="mb-1 ml-5">{t("投稿一覧より過去の投稿の編集、新しい投稿ができます。")}</p>
            </div>
          </div>

          <Row className="d-flex flex-row justify-content-between px-1 mx-0">
            <Col md="2" className="px-0">
              <Button
                block
                className="mt-1 mb-0"
                onClick={() => {
                  this.bindRow();
                  this.setState({
                    modal: !this.state.modal,
                  });

                }}
                color="info"
              >
                <Loader inLoading={inLoading} text={t("投稿作成")} />
              </Button>
            </Col>

            {/* <Col md="2" className="px-0">
              <Button
                block
                className="mt-1 mb-0"
                onClick={this.handleChangeSearch}
                color="info"
              >
                <Loader inLoading={inLoading} text={t("Search")} />
              </Button>
            </Col> */}

            {/* <Col md="6"
              className="px-0 d-flex justify-content-center align-items-center "
            >
              {`TOTAL AMOUNT　`}{" "}
              <span style={{ fontWeight: 600, fontSize: 20 }}>
                {total_amount}
              </span>{" "}
              {`　USDT`}
            </Col> */}
            <Col md="6" className="px-0">
              {inLoading ? (
                <div className="px-0 d-flex justify-content-center align-items-center h-100">
                  <Spinner
                    className={classnames({
                      "position-relative": true,
                      "button-style": !true,
                      visible: true,
                      invisible: !true,
                    })}
                    size="sm"
                  />
                </div>
              ) : (
                <TablePagination
                  component="div"
                  count={count}
                  page={this.state.sch_page}
                  onChangePage={this.handleChangePage}
                  rowsPerPage={this.state.sch_limit}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  // rowsPerPageOptions={[10, 50]}
                  rowsPerPageOptions={[5, 10, 50, { value: -1, label: "All" }]}
                  // labelRowsPerPage = 'Rows per page:'
                  // labelRowsPerPage="Rows"
                  labelRowsPerPage="行数"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${count} 件中 ${from} ~ ${to} 件表示`
                  }
                />
              )}
            </Col>
          </Row>
          <ReactTable
            data={entries.map((prop, key) => {
              const post_date = moment
                .utc(prop.post_date)
                .local()
                .format("YYYY.MM.DD");
              return {
                id: key,
                title: prop.title,
                post_date: post_date,
                actions: (
                  <div className="actions-right">
                    <Button
                      onClick={() => {
                        this.bindRow(prop);
                        // this.bindStaff(prop);
                        this.setState({
                          modal: !this.state.modal,
                        });
                      }}
                      color="info"
                      size="sm"
                      className="btn-icon btn-link edit p-0"
                    >
                      <img
                        alt="..."
                        src={require("assets/img/pencil.png")}
                        width="20px"
                      />
                    </Button>
                  </div>
                ),
              };
            })}
            filterable
            columns={[
              {
                Header: () => <div className="text-center">{t("編集")}</div>,
                accessor: "actions",
                sortable: false,
                filterable: false,
                width: 50,
              },
              {
                Header: t("投稿日"),
                accessor: "post_date",
                width: 120,
              },
              {
                Header: t("タイトル"),
                accessor: "title",
                width: 650,
              },
            ]}
            sortable={false}
            multiSort={false}
            resizable={false}
            filterable={false}
            showPageJump={true}
            defaultSortDesc={false}
            defaultSorted={[]}
            defaultFiltered={[]}
            defaultResized={[]}
            page={this.state.tablePage}
            onPageChange={(pageIndex) => {
              this.setState({ tablePage: pageIndex });
              // window.scrollTo(0, this.props.scrollPosition);
            }}
            // page={2}
            // PaginationComponent={this.Pagination1}
            // defaultPageSize={this.state.rowsPerPage}
            pageSize={this.state.sch_limit}
            // showPagination={false}
            showPageSizeOptions={false}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight"
            previousText={<i className="fas fa-arrow-left"></i>}
            nextText={<i className="fas fa-arrow-right"></i>}
            loading={inLoading}
            // LoadingComponent={Loader}
            loadingText="Loading..."
            noDataText={t("Data Not Found")}
            pageText={t("Page")}
            ofText="/"
            rowsText={t("Row")}
            className="-striped -highlight info-pagination"
          />
        </div>
      </>
    );
    // }
  }
}

export default App;
