import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Modal,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Alert,
  Row,
  Spinner,
} from "reactstrap";
import classnames from "classnames";

import { initReactI18next, useTranslation } from "react-i18next";

const App = (props) => {
  const [t, i18n] = useTranslation();
  return <App2 {...props} t={t} />;
};

class App2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }
  on_users_logout = () => {
    this.props.history.push("/authside/dashboard");
  };

  render() {
    const inLoading = false;

    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="6">
              <Card className="card-login text-center">
                <CardHeader>
                  <CardTitle tag="h4">{this.props.t("ログアウト")}</CardTitle>
                </CardHeader>
                <CardBody className="pt-0 pb-3 pr-4 pl-4">
                  <FormGroup>
                    <Button
                      className="m-1"
                      loading={inLoading}
                      disabled={inLoading}
                      color="info"
                      block={true}
                      outline={false}
                      onClick={(e) => {
                        sessionStorage.removeItem("AccessToken");
                        this.on_users_logout();
                      }}
                    >
                      {!(true && !inLoading) && (
                        <Spinner
                          className={classnames({
                            "position-relative": true,
                            "button-style": !true,
                            visible: inLoading,
                            invisible: !inLoading,
                          })}
                          size="sm"
                        />
                      )}
                      {!(true && inLoading) && (
                        <span
                          className={classnames({
                            invisible: inLoading,
                            visible: !inLoading,
                            "span-style": !true,
                          })}
                        >
                          {this.props.t("ログアウト")}
                        </span>
                      )}
                    </Button>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="full-page-background logout" />
      </div>
    );
  }
}

export default App;
