import React from "react";
import ReactTable from "react-table";
import moment from "moment/moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import ReactDatetime from "react-datetime";
import defaultImage from "assets/img/image_placeholder.jpg";
import classnames from "classnames";

import validator from "validator";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Progress,
  Row,
  Col,
  Button,
  Label,
  Spinner,
} from "reactstrap";
import Loader from "views_in_page/Basic/loader";
import { aryOne } from "views_in_page/Basic/util";
import ModalStaff from "views_in_page/Staff/ModalStaff";

import socketIOClient from "socket.io-client";
const ENDPOINT = "http://127.0.0.1:4001";

export const param = (that) => {
  const upd = {
    staff_id: that.state.staff_id,
    username: that.state.staff_name,
    password: that.state.password,
    permission: that.state.option_permission.value,
  };
  return upd;
};

export const options_permission = [
  // { value: 0, label: "エンジニア" },
  { value: 1, label: "Admin" },
  { value: 2, label: "staff" },
  // { value: null, label: "All" },
];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.bindStaff = this.bindStaff.bind(this);

    this.state = {
      alert: null,
      modalStaff: false,
    };
  }


  bindStaff = (prop) => {
    this.setState({
      staff_id: prop ? prop.staff_id : "",
      staff_name: prop ? prop.staff_name : "",
      password: "",
      option_permission: prop
        ? options_permission.filter((x) => x.value === prop.permission)[0]
        : {},
    });
  };

  warningWithConfirmMessage = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Delete it？"
          onConfirm={() => this.successDelete()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Delete"
          cancelBtnText="Cancel"
          showCancel
        >
          Cannot recover
        </ReactBSAlert>
      ),
    });
  };

  successDelete = () => {
    this.props.on_delete_staffs({
      data: param(this),
      page: () => {
        this.setState({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="削除完了"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="info"
            >
              削除されました。
            </ReactBSAlert>
          ),
        });
      },
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  componentDidMount() {
    this.props.on_get_staffs({
      data: {
      },
    });
  }



  render() {
    const t = this.props.t;

    const staffs = this.props.staffs.isFetching ? [] : this.props.staffs.staffs;

    const inLoading =
      this.props.staffs.isFetching || this.props.staffs.isLoading;

    return (
      <>
        <div className="content">
          {this.state.alert}
          <ModalStaff that={this} />

          <div className="info info-horizontal ml-4">
            {/* <div className="icon icon-info">
              <i aria-hidden={true} className="fas fa-splotch" />
            </div> */}
            <div className="description">
              <h4 className="info-title">
                <img
                  alt="..."
                  src={require("assets/img/staff.png")}
                  width="30px"
                />{" "}
                {t("staffs")}
              </h4>
              <p className="mb-1 ml-5">{t("Edit staffs")}</p>
            </div>
          </div>

          <Row className="d-flex flex-row justify-content-between px-1 mx-0">
            <Col md="2" className="px-0">
              <Button
                block
                className="mt-1 mb-0"
                onClick={() => {
                  this.bindStaff();
                  this.setState({
                    modalStaff: !this.state.modalStaff,
                  });
                }}
                color="info"
              >
                <Loader inLoading={inLoading} text={t("Register")} />
              </Button>
            </Col>
            <Col md="2" className="px-0"></Col>
          </Row>
          <ReactTable
            data={staffs.map((prop, key) => {
              const permission = aryOne(
                options_permission,
                (x) => x.value === prop.permission,
                (x) => x.label
              );
              return {
                id: key,
                staff_id: prop.staff_id,
                // staff_name: prop.staff_name,
                username: prop.username,
                permission: permission,
                actions: (
                  <div className="actions-right">
                    <Button
                      onClick={() => {
                        this.bindStaff(prop);
                        this.setState({
                          modalStaff: !this.state.modalStaff,
                        });
                      }}
                      color="primary"
                      size="sm"
                      className="btn-icon btn-link edit p-0"
                    >
                      <img
                        alt="..."
                        src={require("assets/img/pencil.png")}
                        width="20px"
                      />
                    </Button>
                    {/* <Button
                      onClick={() => {
                        this.bindStaff(prop);
                        this.warningWithConfirmMessage();

                        // this.setState({
                        //   modalStaff: !this.state.modalStaff,
                        // });
                      }}
                      color="primary"
                      size="sm"
                      className="btn-icon btn-link edit p-0"
                    >
                      <img
                        alt="..."
                        src={require("assets/img/trash.png")}
                        width="20px"
                      />
                    </Button> */}
                  </div>
                ),
              };
            })}
            filterable
            columns={[
              {
                Header: () => <div className="text-center">{t("Edit")}</div>,
                accessor: "actions",
                sortable: false,
                filterable: false,
                width: 50,
              },
              {
                Header: t("staff_id"),
                accessor: "staff_id",
                width: 350,
              },
              {
                Header: t("username"),
                accessor: "username",
                width: 350,
              },
              {
                Header: t("permission"),
                accessor: "permission",
                width: 170,
              },
            ]}
            sortable={false}
            multiSort={false}
            resizable={false}
            filterable={false}
            showPageJump={true}
            defaultSortDesc={false}
            defaultSorted={[]}
            defaultFiltered={[]}
            defaultResized={[]}
            page={this.state.tablePage}
            onPageChange={(pageIndex) => {
              this.setState({ tablePage: pageIndex });
              // window.scrollTo(0, this.props.scrollPosition);
            }}
            // page={2}
            // PaginationComponent={this.Pagination1}
            defaultPageSize={10}
            // showPagination={false}
            showPageSizeOptions={false}
            showPaginationTop={true}
            showPaginationBottom={false}
            className="-striped -highlight"
            previousText={<i className="fas fa-arrow-left"></i>}
            nextText={<i className="fas fa-arrow-right"></i>}
            loading={this.props.staffs.isFetching}
            // LoadingComponent={Loader}
            loadingText="Loading..."
            noDataText={t("Data Not Found")}
            pageText={t("Page")}
            ofText="/"
            rowsText={t("Row")}
            className="-striped -highlight info-pagination"
          />
        </div>
      </>
    );
    // }
  }
}

export default App;
