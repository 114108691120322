import auth from "views_out_page/authContainer";

const routes = [
  {
    path: "/auth",
    name: "ログイン",
    icon: "nc-icon nc-key-25",
    component: auth,
    layout: "/out",
    visible: true,
  },
];

export default routes;
