import React, { FC, useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import htmlToDraft from "html-to-draftjs";

import notification from "views_in_page/Basic/notification";
import NotificationAlert from "react-notification-alert";

import { initReactI18next, useTranslation } from "react-i18next";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import moment from "moment/moment";
import classnames from "classnames";
import Loader from "views_in_page/Basic/loader";

import {
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { post_entries } from "stores_api/entries";
import { put_entries } from "stores_api/entries";
import { delete_entries } from "stores_api/entries";

import { post_galleries } from "stores_api/galleries";

import { param } from "../entries";

// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { EditorState, ContentState, convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";

import Ckeditor from "./Ckeditor";

// import Wysiwyg from "../wysiwyg";

// import ColorPic from "./ColorPic";
// import Wysiwyg from "../stories/Basic/index";
// import Wysiwyg from "../stories/BasicControlled/index";
//よくわからん
// import Wysiwyg from "../stories/BasicContentState/index";

// Editor with only selected toolbar options
// import Wysiwyg from "../stories/ControlledSelectedOptions/index";

// import Wysiwyg from "../stories/ConvertFromHTML/index";
// import Wysiwyg from "../stories/ConvertFromRawDraftContent/index";

// import Wysiwyg from "../stories/ConvertToHTML/index";
// import Wysiwyg from "../stories/ConvertToMarkdown/index";
// import Wysiwyg from "../stories/ConvertToRawDraftContent/index";

// import Wysiwyg from "../stories/CustomToolbar/index";

// 組み込みリンク
// import Wysiwyg from "../stories/Embeddable/index";

// 入力中にバーが出るけど使いにくい
// import Wysiwyg from "../stories/FloatingToolbar/index";

// 入力が終わったとき、入力し始めた時
// import Wysiwyg from "../stories/FocusBlurCallbacks/index";

// ハッシュタグ
// import Wysiwyg from "../stories/HashTag/index";

// import Wysiwyg from "../stories/I18n/index";

// import Wysiwyg from "../stories/ImageDataURI/index";
// import Wysiwyg from "../stories/ImageUpload/index";

// 辞書キーワード
// import Wysiwyg from "../stories/Mention/index";

// ReadOnly
// import Wysiwyg from "../stories/ReadOnly/index";

// スペルチェック
// import Wysiwyg from "../stories/SpellCheck/index";

// ツールバー付けたり消したり
// import Wysiwyg from "../stories/ToolbarHidden/index";

const AppContainer = ({ that }) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();

  const [file, setFile] = React.useState(null);
  // const [html, set_html] = React.useState("");

  // content: prop ? prop.content : "",
  // entry_id: prop ? prop.entry_id : "",
  // post_date: prop ? prop.post_date : "",
  // title: prop ? prop.title : "",

  // const [editorState, set_editorState] = React.useState(that.state.editorState_first);

  // const onEditorStateChange = (editorState) => {
  //   that.setState({
  //     editorState,
  //   });
  // };

  React.useEffect(() => {
    // window.jQuery(".modal-body").load(function() {
    //   window.jQuery("#datecalendar input").attr("readonly", "readonly");
    //   alert(window.jQuery("#datecalendar input").length);
    // });
  }, []);

  const valid = () => {
    return true;
  };

  const page = () => {
    that.setState({
      modal: !that.state.modal,
    });
  };

  const on_post_entries = (that) => {
    if (!valid()) return;
    dispatch(
      post_entries({
        data: param(that),
        toast,
        page,
      })
    );
  };

  const on_put_entries = (that) => {
    if (!valid()) return;
    dispatch(
      put_entries({
        data: param(that),
        search: {},
        toast,
        page,
      })
    );
  };

  const on_delete_entries = (that) => {
    if (!valid()) return;
    dispatch(
      delete_entries({
        data: param(that),
        toast,
        page,
      })
    );
  };

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      dispatch(
        post_galleries({
          data: { image: file },
          page: (url) => {
            resolve({
              data: { link: url },
            });
          },
          toast: (error) => {
            reject(error);
          },
        })
      );
    });
  };

  const uploadPromise = (resolve, reject) => {
    dispatch(
      post_galleries({
        data: { image: file },
        page: (url) => {
          resolve({
            data: { link: url },
          });
        },
        toast: (error) => {
          reject(error);
        },
      })
    );
  };

  const toast = (inMessage) => {
    notification(ref.current, inMessage);
  };
  const inLoading = false;
  // const inLoading = that.props.entries.isFetching || that.props.entries.isLoading;

  const isDisabled = false;
  const isDisabledstatus = false;



  
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={ref} />
      </div>
      <Modal
        size="xl"
        // isOpen={that.state.modal}
        isOpen={that.state.modal}
        toggle={() => {
          that.setState({
            modal: !that.state.modal,
          });
        }}
      >
        <div className="modal-header justify-content-center pb-0">
          <h4 className="title title-up mt-0">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                that.setState({
                  modal: !that.state.modal,
                });
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            {/* <i
              aria-hidden={true}
              className="fas fa-splotch icon icon-primary"
            /> */}
            <img
              alt="..."
              src={require("assets/img/picture5.png")}
              width="30px"
            />{" "}
            {/* <i aria-hidden={true} className="fas fa-award icon icon-danger" /> */}{" "}
            {t("投稿")}
          </h4>
        </div>
        <div
          className="modal-body"
          style={{ minHeight: 500 }}
         
        >
          <Row className="d-flex flex-row px-1 mx-0">
            <Col md="2" className="px-0 pr-2">
              <FormGroup id="datecalendar">
                <h6>
                  {t("投稿日")} <span className="icon-danger"></span>
                </h6>
                <ReactDatetime
                  className="primary"
                  inputProps={{
                    className: "form-control",
                    readOnly:"readOnly"
                    // placeholder: "From",
                  }}
                  // value={date}
                  value={that.state.post_date}
                  timeFormat={false}
                  dateFormat="YYYY/MM/DD"
                  onChange={(m) => {
                    that.setState({ post_date: m });
                  }}
                  // onFocus={() => {
                  //   window
                  //     .jQuery("#datecalendar input")
                  //     .attr("readonly", "readonly");
                  // }}
                />
              </FormGroup>
            </Col>
            <Col md="4" className="px-0 pr-2">
              <h6>
                {t("タイトル")} <span className="icon-danger"></span>
              </h6>
              <FormGroup>
                <Input
                  className="border-input"
                  // disabled={isDisabledstatus}
                  // placeholder="enter price"
                  type="text"
                  value={that.state.title}
                  onChange={(e) => {
                    const val = e.target.value;
                    that.setState({ title: val });
                  }}
                />
              </FormGroup>
            </Col>

            {!that.state.entry_id ? (
              <Col md="6" className="d-flex  align-self-end">
                <FormGroup className="w-100">
                  <Button
                    block
                    className="btn mb-0"
                    color="info"
                    type="submit"
                    onClick={() => {
                      on_post_entries(that);
                    }}
                  >
                    <Loader inLoading={inLoading} text={t("投稿")} />
                  </Button>
                </FormGroup>
              </Col>
            ) : null}
            {that.state.entry_id ? (
              <Col md="3" className="d-flex  align-self-end">
                <FormGroup className="w-100">
                  <Button
                    block
                    className="btn mb-0"
                    color="primary"
                    disabled={isDisabled}
                    type="submit"
                    onClick={() => {
                      on_put_entries(that);
                    }}
                  >
                    <Loader inLoading={inLoading} text={t("保存")} />
                  </Button>
                </FormGroup>
              </Col>
            ) : null}
            {that.state.entry_id ? (
              <Col md="3" className="d-flex  align-self-end">
                <FormGroup className="w-100">
                  <Button
                    block
                    className="btn mb-0"
                    color="danger"
                    disabled={isDisabled}
                    type="submit"
                    onClick={() => {
                      on_delete_entries(that);
                    }}
                  >
                    <Loader inLoading={inLoading} text={t("削除")} />
                  </Button>
                </FormGroup>
              </Col>
            ) : null}

            {/* <Col md="8" className="px-0 pr-2">
              <h6>
                {t("HTML")} <span className="icon-danger"></span>
              </h6>
              <FormGroup>
                <textarea
                  // readOnly
                  className="rdw-storybook-textarea"
                  value={draftToHtml(
                    convertToRaw(that.state.editorState.getCurrentContent())
                  )}
                  onChange={(e) => {
                    const html = e.target.value;

                    const contentBlock = htmlToDraft(html);
                    const contentState = ContentState.createFromBlockArray(
                      contentBlock.contentBlocks
                    );
                    const editorState = EditorState.createWithContent(
                      contentState
                    );

                    that.setState({ editorState });
                  }}
                />
              </FormGroup>
            </Col> */}
          </Row>
          <h6>{t("投稿内容")}</h6>
          <Ckeditor
            html={that.state.html}
            set_html={(data) => {
              that.setState({ html: data });
            }}
            dispatch={dispatch}
          />
        </div>
      </Modal>
    </>
  );
};
export default AppContainer;

// <div className="rdw-storybook-root">
// <Editor
//   toolbarClassName="rdw-storybook-toolbar"
//   wrapperClassName="rdw-storybook-wrapper"
//   editorClassName="rdw-storybook-editor"
//   // wrapperClassName="demo-wrapper"
//   // editorClassName="demo-editor"
//   editorState={that.state.editorState}
//   onEditorStateChange={onEditorStateChange}
//   toolbar={{
//     // inline: { inDropdown: true },
//     // list: { inDropdown: true },
//     // textAlign: { inDropdown: true },
//     // link: { inDropdown: true },
//     // history: { inDropdown: true },
//     image: {
//       previewImage: true,
//       uploadCallback: uploadImageCallBack,
//       alt: { present: true },
//     },
//     colorPicker: { component: ColorPic },
//   }}
//   // onBlur={this.onBlur}
//   // onFocus={this.onFocus}
//   // readOnly
// />
// </div>
