import {
  api_GlobalToken,
  api_AccessToken,
  flow_get_tokens_global,
  api_ApiKey,
  err
} from "../stores/sagas";

import { fork, take, put, join, select, call } from "redux-saga/effects";
import { createAction, createReducer } from "redux-act";
import { socket } from "../AppRouter";

export const post_auth = createAction("post_auth");

export const loadingFalse = createAction("loadingFalse");
export const loadingFalseAccessToken = createAction("loadingFalseAccessToken");

export const auth = createReducer(
  {
    [post_auth]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [loadingFalseAccessToken]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      sessionStorage.setItem("AccessToken", payload.data.token);
      // socket.extraHeaders={'AccessToken': payload.data.token}

      newState.isLoading = false;
      return newState;
    },
    [loadingFalse]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
    isLogin: false,
  }
);

export function* flow_post_auth() {
  while (true) {
    const { payload } = yield take(post_auth);

    const { data, error } = yield call(
      api_ApiKey,
      `auth`,
      "post",
      payload.data
    );
    if (!error) {
      yield put(loadingFalseAccessToken({ data }));
      !payload.page || payload.page();
    } else {
      yield put(loadingFalse({ error }));
      !payload.toast || payload.toast(err(error));

    }
  }
}
