import { combineReducers } from "redux";

import { auth } from "../stores_api/auth";
import { entries } from "../stores_api/entries";
import { galleries } from "../stores_api/galleries";
import { staffs } from "../stores_api/staffs";

export default combineReducers({
  auth,
  entries,
  galleries,
  staffs,
});
