import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Modal,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Alert,
  Row,
  Spinner,
} from "reactstrap";
import classnames from "classnames";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }
  on_username = (val) => val.length > 0;

  on_password = (val) => val.length > 0;

  on_post_auth = () => {
    const { username, password } = this.state;
    this.props.on_post_auth({ username, password });
  };

  render() {
    const inLoading = this.props.auth.isFetching || this.props.auth.isLoading;

    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="6">
              <Card className="card-login text-center">
                <CardHeader>
                  <CardTitle tag="h4">{this.props.t("ログイン")}</CardTitle>
                </CardHeader>
                <CardBody className="pt-0 pb-3 px-4">
                  <FormGroup
                    className={`has-label ${this.state.usernameState}`}
                  >
                    <Input
                      name="username"
                      type="text"
                      placeholder={this.props.t("メールアドレス")}
                      onChange={(e) => {
                        const val = e.target.value;
                        this.setState({ username: val });
                        if (this.on_username(val)) {
                          this.setState({ usernameState: "has-success" });
                        } else {
                          this.setState({ usernameState: "has-danger" });
                        }
                      }}
                    />
                    {this.state.usernameState === "has-danger" ? (
                      <label className="error">
                        {this.props.t("正しいメールアドレスを入力してください")}
                      </label>
                    ) : null}
                  </FormGroup>

                  <FormGroup
                    className={`has-label ${this.state.passwordState}`}
                  >
                    <Input
                      name="password"
                      type="password"
                      placeholder={this.props.t("パスワード")}
                      onChange={(e) => {
                        const val = e.target.value;
                        this.setState({ password: val });
                        if (this.on_password(val)) {
                          this.setState({ passwordState: "has-success" });
                        } else {
                          this.setState({ passwordState: "has-danger" });
                        }
                      }}
                    />
                    {this.state.passwordState === "has-danger" ? (
                      <label className="error">
                        {this.props.t("有効なパスワードを入力してください。")}
                      </label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="text-center mb-0">
                    <Col className=""></Col>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      className="m-1"
                      loading={inLoading}
                      disabled={inLoading}
                      color="info"
                      block={true}
                      outline={false}
                      onClick={(e) => {
                        e.preventDefault();

                        if (this.state.usernameState !== "has-success") {
                          this.setState({ usernameState: "has-danger" });
                        }

                        if (this.state.passwordState !== "has-success") {
                          this.setState({ passwordState: "has-danger" });
                        }

                        if (
                          this.state.usernameState === "has-success" &&
                          this.state.passwordState === "has-success"
                        ) {
                          this.on_post_auth(this);
                        }
                      }}
                    >
                      {!(true && !inLoading) && (
                        <Spinner
                          className={classnames({
                            "position-relative": true,
                            "button-style": !true,
                            visible: inLoading,
                            invisible: !inLoading,
                          })}
                          size="sm"
                        />
                      )}
                      {!(true && inLoading) && (
                        <span
                          className={classnames({
                            invisible: inLoading,
                            visible: !inLoading,
                            "span-style": !true,
                          })}
                        >
                          {this.props.t("ログイン")}
                        </span>
                      )}
                    </Button>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="full-page-background login" />
      </div>
    );
  }
}

export default App;
