import React from "react";
import { Button, Icon, Input, Dropdown, Menu, Switch } from "antd";

import Select from "react-select";
import { useEffect, useRef, useState } from "react";

const options = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
];

export default function({
  shuffle,
  search,
  setColumns,
  setMargin,
  setHeight,
  columns,
  margin,
}) {
  const [option, set_option] = useState({ value: 4, label: "4" });

  return (
    <>
      <div
        className="select-transparent"
        style={{
          position: "absolute",
          top: 30,
          left: 30,
          zIndex: 9999,
          backgroundColor: "transparent",
        }}
      >
        <Select
          className="react-select default"
          classNamePrefix="react-select"
          name="option"
          value={option}
          onChange={(value) => {
            const val = value;
            set_option(val);
            setColumns(val.value);
          }}
          options={options}
          // placeholder="選択してください。"
        />
      </div>
      {/* <div
        className="header select-transparent"
        style={{
          position: "absolute",
          top: 30,
          zIndex: 9999,
          backgroundColor: "transparent",
        }}
      > */}

      {/* <Button type="primary" onClick={shuffle}>
        Shuffle
      </Button> */}
      {/* <Input
        style={{ marginLeft: 15, minWidth: 130, maxWidth: 300 }}
        suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="input search text"
        onChange={search}
      /> */}

      {/* <Dropdown
          trigger={["click"]}
          overlay={
            <Menu onClick={setColumns}>
              <Menu.Item key="1">1</Menu.Item>
              <Menu.Item key="2">2</Menu.Item>
              <Menu.Item key="3">3</Menu.Item>
              <Menu.Item key="4">4</Menu.Item>
              <Menu.Item key="5">5</Menu.Item>
              <Menu.Item key="6">6</Menu.Item>
            </Menu>
          }
        >
          <Button style={{ marginLeft: 15, backgroundColor: "transparent" }}>
            {columns} <Icon type="down" />
          </Button>
        </Dropdown> */}
      {/* <Dropdown
        trigger={['click']}
        overlay={
          <Menu onClick={setMargin}>
            <Menu.Item key="0">0</Menu.Item>
            <Menu.Item key="20">20</Menu.Item>
            <Menu.Item key="40">40</Menu.Item>
            <Menu.Item key="70">70</Menu.Item>
          </Menu>
        }>
        <Button style={{ marginLeft: 15, minWidth: 130 }}>
          {margin} px margin <Icon type="down" />
        </Button>
      </Dropdown> */}
      {/* <span style={{ marginLeft: 15 }}>Individual height</span> */}
      {/* <Switch style={{ marginLeft: 15 }} defaultChecked onChange={setHeight} /> */}
      {/* </div> */}
    </>
  );
}
