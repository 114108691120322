import "antd/dist/antd.css";
import "./styles.css";
import React, { Component, Fragment } from "react";
import { render } from "react-dom";
import lodash from "lodash";
import { Icon } from "antd";
import data from "./data";
import Header from "./Header";
import { Grid, Slug, Fade } from "mauerwerk";
import {
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Row,
  Col,
  Spinner,
} from "reactstrap";

const Cell = ({
  toggle,
  name,
  height,
  description,
  css,
  maximized,
  set_header,
}) => (
  <div
    className="cell"
    style={{ backgroundImage: css, cursor: !maximized ? "pointer" : "auto" }}
    // onClick={!maximized ? toggle : undefined}
    onClick={
      !maximized
        ? () => {
            set_header(false);
            // alert("open");
            toggle();
          }
        : () => {
            // set_header(true);
            // alert("close");
          }
    }
  >
    <Fade show={maximized} delay={maximized ? 400 : 0}>
      <div className="details">
        <Slug delay={600}>
          {/* <div className="circle" style={{ background: css }} /> */}
          <div className="close">
            {/* <Icon type="close" style={{ cursor: "pointer" }} onClick={toggle} /> */}
            <Icon
              type="close"
              style={{ cursor: "pointer" }}
              onClick={() => {
                set_header(true);
                toggle();
              }}
            />
          </div>
          {/* <Button
            block
            className="btn mb-0"
            color="info"
            type="submit"
            onClick={() => {
            }}>
            <Loader inLoading={inLoading} text={t('Register')} />
          </Button> */}
          
          <h1>{maximized?"tr":"sdf"}</h1>
          <h1>{name}</h1>
          <p>{description}</p>
        </Slug>
      </div>
    </Fade>
    <Fade
      show={!maximized}
      // from={{ opacity: 0, transform: 'translate3d(0,140px,0)' }}
      // enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
      // leave={{ opacity: 0, transform: 'translate3d(0,-50px,0)' }}
      from={{ opacity: 0, transform: "translate3d(0,140px,0)" }}
      enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
      leave={{ opacity: 0, transform: "translate3d(0,-50px,0)" }}
      delay={maximized ? 0 : 400}
    >
      {/* <div className="default">{name}</div> */}
      <div className="default"></div>
    </Fade>
  </div>
);
let header = true;
class App extends Component {
  state = {
    data,
    columns: 4,
    margin: 20,
    filter: "",
    height: false,
    heights: 200,
    header: true,
  };
  search = (e) => this.setState({ filter: e.target.value });
  shuffle = () =>
    this.setState((state) => ({ data: lodash.shuffle(state.data) }));
  // setColumns = e =>
  // this.setState({ columns: parseInt(e.key), heights: parseInt(800 / e.key) })
  setColumns = (v) =>
    this.setState({ columns: parseInt(v), heights: parseInt(800 / v) });
  setMargin = (e) => this.setState({ margin: parseInt(e.key) });
  setHeight = (e) => this.setState({ height: e });

  set_header = (e) => this.setState({ header: e });

  render() {
    const data = this.state.data.filter(
      (d) => d.name.toLowerCase().indexOf(this.state.filter) != -1
    );
    return (
      <div className="gridmain" style={{ height: "100vh" }}>
        {header ? (
          <Header
            {...this.state}
            search={this.search}
            shuffle={this.shuffle}
            setColumns={this.setColumns}
            setMargin={this.setMargin}
            setHeight={this.setHeight}
          />
        ) : null}
        <Grid
          className="grid"
          // Arbitrary data, should contain keys, possibly heights, etc.
          data={data}
          // Key accessor, instructs grid on how to fet individual keys from the data set
          keys={(d) => d.name}
          // Can be a fixed value or an individual data accessor
          // heights={this.state.height ? d => d.height : 200}
          heights={this.state.heights}
          // heights={200}
          // Number of columns
          columns={this.state.columns}
          // Space between elements
          margin={this.state.margin}
          // Removes the possibility to scroll away from a maximized element
          lockScroll={false}
          // Delay when active elements (blown up) are minimized again
          closeDelay={200}
        >
          {(data, maximized, toggle) => {
            return (
              <Cell
                {...data}
                maximized={maximized}
                toggle={toggle}
                set_header={this.set_header}
              />
            );
          }}
        </Grid>
      </div>
    );
  }
}

export default App;
