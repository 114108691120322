import React from "react";
import ReactDOM from "react-dom";
import { AppRouter } from "./AppRouter";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/component.scss?v=1.1.0";
import "assets/scss/common.scss?v=1.1.0";
import "perfect-scrollbar/css/perfect-scrollbar.css";


import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import "core-js/stable"
import "regenerator-runtime/runtime"


import { store, persistor } from "stores/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <AppRouter />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
