import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container
} from "reactstrap";
import { initReactI18next, useTranslation } from 'react-i18next';

const OutNavbar = (props) => {
  const [t, i18n] = useTranslation();
  return <OutNavbar2 {...props} t={t}/>;
};


class OutNavbar2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
 
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (this.state.collapseOpen) {
      newState["color"] = "navbar-transparent";
    } else {

      if (window.innerWidth < 992) {
        newState["color"] = "bg-white";
      }
    }
    this.setState(newState);
  };
  render() {
    return (
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <Container>
          <div className="navbar-wrapper">
            <NavbarBrand href="#pablo" onClick={e => {
              // let newState = {
              //   collapseOpen: !this.state.collapseOpen
              // };
              // if (!this.state.collapseOpen) {
              //   newState["color"] = "bg-white";
              // } else {
              //   newState["color"] = "navbar-transparent";
              // }
              // this.setState(newState);
              e.preventDefault();
              }}>
              {/* 仮想通貨宝くじ collapseOpen{this.state.collapseOpen===true?"true":"false"}{window.innerWidth} */}
              {this.props.t("松永興業株式会社")}
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            isOpen={this.state.collapseOpen}
            className="justify-content-end"
            navbar
          >
            <Nav navbar>
              {/* <NavItem>
                <NavLink to="/outside/dashboard" className="nav-link" onClick={this.toggleCollapse}>
                  <i className="nc-icon nc-layout-11" />
              {this.props.t("ホーム")}
                  
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/out/users_register_email" className="nav-link" onClick={this.toggleCollapse}>
                  <i className="nc-icon nc-book-bookmark" />
              {this.props.t("メンバー登録はこちら")}
                  
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/out/users_login" className="nav-link" onClick={this.toggleCollapse}>
                  <i className="nc-icon nc-tap-01" />
              {this.props.t("ログイン")}
                  
                </NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default OutNavbar;
