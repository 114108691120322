import React, { FC, useEffect, useRef, useState } from "react";
import classnames from "classnames";

import {
  Spinner,
} from "reactstrap";

const Loader = ({ inLoading , text }) => {

  return (
    <>
      {!(true && !inLoading) && (
        <Spinner
          className={classnames({
            "position-relative": true,
            "button-style": !true,
            visible: inLoading,
            invisible: !inLoading,
          })}
          size="sm"
        />
      )}
      {!(true && inLoading) && (
        <span
          className={classnames({
            invisible: inLoading,
            visible: !inLoading,
            "span-style": !true,
          })}
        >
          {text}
        </span>
      )}
    </>
  );
};
export default Loader;
