import React from "react";

const notification = (refs, inMessage) => {
  if (refs) {
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>{inMessage}</div>
        </div>
      ),
      type: "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 17,
    };
    refs.notificationAlert(options);
  }
};
export default notification;
