import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import OutLayout from "layouts/Out.jsx";
import OutSideLayout from "layouts/OutSide.jsx";
import InLayout from "layouts/In.jsx";
import InSideLayout from "layouts/InSide.jsx";
import Push from "views_in_page/Push";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import { get_tokens_global } from "stores_api/tokens_global";

import notification from "views_in_page/Basic/notification";

import getOptions from "stores/sagas";
import axios from "axios";

import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

import enJson from "./locales/en.json";
import jaJson from "./locales/ja.json";

import socketIOClient from "socket.io-client";
/* eslint-disable */
require("dotenv").config();

const ENDPOINT = process.env.REACT_APP_API_URL;

i18n
  // .use(LngDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en: { translation: enJson },
      ja: { translation: jaJson },
    },
    lng: "en",
    fallbackLng: false,
    returnEmptyString: false,
  });


export const AppRouter = () => {
  const language = window.navigator.languages;
  console.log(language);

  // const [t, i18n] = useTranslation();
  // const [lang, setLang] = useState('en');
  const [lang, setLang] = useState(language[0]);
 
  useEffect(() => {
    // i18n.changeLanguage(lang);
    i18n.changeLanguage(language[0]);
  }, [lang, i18n]);
  // }, []);

  const hist = createBrowserHistory();

  const dispatch = useDispatch();
  const ref = useRef();
  // getToken();

  // useEffect(() => {
  // dispatch(get_tokens_global({ toast }));sessionStorage.getItem("AccessToken") !== null
  // }, []);
 
  const toast = (inMessage) => {
    notification(ref.current, inMessage);
  };

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={ref} />
      </div>
    
      <Router history={hist}>
        <Switch>
          <Route exact path="/" component={Push} />
          <Route path="/out" render={(props) => <OutLayout {...props} />} />
          <Route
            path="/outside"
            render={(props) => <OutSideLayout {...props} />}
          />
          <Out>
            <Route path="/in" render={(props) => <InLayout {...props} />} />
            <Route
              path="/inside"
              render={(props) => <InSideLayout {...props} />}
            />
            {/* <Redirect to="/outside/dashboard" /> */}
            {/* <Redirect to="/inside/userDashboard" /> */}
          </Out>
        </Switch>
      </Router>
    </>
  );
};

const Out = (props) => {
  const auth = useSelector((state) => state.auth);
  const isLogin =
    auth.isLogin || sessionStorage.getItem("AccessToken") !== null;

  // const isLogin = true;

  return isLogin ? (
    props.children
  ) : (
    <>
      <Redirect to="/out/auth" />
    </>
  );
};
