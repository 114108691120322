import React, { FC, useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import notification from "views_in_page/Basic/notification";
import NotificationAlert from "react-notification-alert";

import { initReactI18next, useTranslation } from "react-i18next";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import moment from "moment/moment";
import classnames from "classnames";
import Loader from "views_in_page/Basic/loader";

import {
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { post_staffs } from "stores_api/staffs";
import { put_staffs } from "stores_api/staffs";
import { delete_staffs } from "stores_api/staffs";
import { options_permission } from "../staffs";
import { param } from "../staffs";

const AppContainer = ({ that }) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();

  const [file, setFile] = React.useState(null);

  const on_staff_name = (val) => {
    if (true) {
      return true;
    }
    return false;
  };

  const on_password = (val) => {
    if (true) {
      return true;
    }
    return false;
  };

  const on_option_permission = (val) => val !== null;

  const valid = () => {
    // if (that.state.titleState !== "has-success") {
    //   that.setState({
    //     titleState: "has-danger",
    //   });
    // }

    // if (that.state.titleState === "has-success") {
    //   return true;
    // } else {
    //   return false;
    // }
    return true;
  };

  const page = () => {
    that.setState({
      modalStaff: !that.state.modalStaff,
    });
  };

  const on_post_staffs = (that) => {
    if (!valid()) return;
    dispatch(
      post_staffs({
        data: param(that),
        toast,
        page,
      })
    );
  };

  const on_put_staffs = (that) => {
    if (!valid()) return;
    dispatch(
      put_staffs({
        data: param(that),
        search: {},
        toast,
        page,
      })
    );
  };

  const on_delete_staffs = (that) => {
    if (!valid()) return;
    dispatch(
      delete_staffs({
        data: param(that),
        toast,
        page,
      })
    );
  };




  
  const toast = (inMessage) => {
    notification(ref.current, inMessage);
  };
  const inLoading = that.props.staffs.isFetching || that.props.staffs.isLoading;

  const isDisabled = false;
  const isDisabledstatus = false;

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={ref} />
      </div>
      <Modal
        size="md"
        // isOpen={that.state.modalStaff}
        isOpen={that.state.modalStaff}
        toggle={() => {
          that.setState({
            modalStaff: !that.state.modalStaff,
          });
        }}
      >
        <div className="modal-header justify-content-center pb-0">
          <h4 className="title title-up mt-0">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                that.setState({
                  modalStaff: !that.state.modalStaff,
                });
              }}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
            {/* <i
              aria-hidden={true}
              className="fas fa-splotch icon icon-primary"
            /> */}
            <img alt="..." src={require("assets/img/staff.png")} width="30px" />{" "}
            {/* <i aria-hidden={true} className="fas fa-award icon icon-danger" /> */}{" "}
            {t("staff")}
          </h4>
        </div>
        <div className="modal-body">
          <Card className="card-plain mb-0">
            <CardBody>
              <div className="section py-0">
                <Container>
                  <div>
                    <Row className="price-row">
                      <Col md="12">
                        <h6>
                          {t("STAFF_NAME")}{" "}
                          <span className="icon-danger">*</span>
                        </h6>
                        <FormGroup>
                          <Input
                            className="border-input"
                            disabled={isDisabledstatus}
                            // placeholder="enter price"
                            type="text"
                            value={that.state.staff_name}
                            onChange={(e) => {
                              const val = e.target.value;
                              that.setState({ staff_name: val });
                              if (on_staff_name(val)) {
                                that.setState({
                                  staff_nameState: "has-success",
                                });
                              } else {
                                that.setState({
                                  staff_nameState: "has-danger",
                                });
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <h6>
                          {t("PERMISSION")}{" "}
                          <span className="icon-danger">*</span>
                        </h6>
                        <FormGroup
                          className={`has-label ${that.state.option_permissionState}`}
                        >
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            isDisabled={isDisabledstatus}
                            options={options_permission}
                            value={that.state.option_permission}
                            onChange={(value) => {
                              const val = value;
                              that.setState({ option_permission: val });
                              if (on_option_permission(val)) {
                                that.setState({
                                  option_permissionState: "has-success",
                                });
                              } else {
                                that.setState({
                                  option_permissionState: "has-danger",
                                });
                              }
                            }}
                            placeholder="選択してください。"
                          />
                          {that.state.option_permissionState ===
                          "has-danger" ? (
                            <label className="error">
                              選択されていません。
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <h6>
                          {t("PassWord")} <span className="icon-danger"></span>
                        </h6>
                        <FormGroup>
                         
                          <Input
                            className="border-input"
                            disabled={isDisabledstatus}
                            // placeholder="enter price"
                            type="password"
                            value={that.state.password}
                            onChange={(e) => {
                              const val = e.target.value;
                              that.setState({ password: val });
                              if (on_password(val)) {
                                that.setState({
                                  passwordState: "has-success",
                                });
                              } else {
                                that.setState({
                                  passwordState: "has-danger",
                                });
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="buttons-row">
                      <Col md="12" sm="12">
                        {!that.state.staff_id ? (
                          <FormGroup>
                            <Button
                              block
                              className="btn mb-0"
                              color="info"
                              type="submit"
                              onClick={() => {
                                on_post_staffs(that);
                              }}
                            >
                              <Loader
                                inLoading={inLoading}
                                text={t("Register")}
                              />
                            </Button>
                          </FormGroup>
                        ) : null}
                      </Col>
                      <Col md="6">
                        {that.state.staff_id ? (
                          <FormGroup>
                            <Button
                              block
                              className="btn mb-0"
                              color="primary"
                              disabled={isDisabled}
                              type="submit"
                              onClick={() => {
                                on_put_staffs(that);
                              }}
                            >
                              <Loader inLoading={inLoading} text={t("Save")} />
                            </Button>
                          </FormGroup>
                        ) : null}
                      </Col>
                      <Col md="6">
                        {that.state.staff_id ? (
                          <FormGroup>
                            <Button
                              block
                              className="btn mb-0"
                              color="danger"
                              disabled={isDisabled}
                              type="submit"
                              onClick={() => {
                                on_delete_staffs(that);
                              }}
                            >
                              <Loader
                                inLoading={inLoading}
                                text={t("Delete")}
                              />
                            </Button>
                          </FormGroup>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};
export default AppContainer;
