import {
  api_GlobalToken,
  api_AccessToken,
  flow_get_tokens_global,
  err
} from "../stores/sagas";
import { fork, take, put, join, select, call } from "redux-saga/effects";
import { createAction, createReducer } from "redux-act";

export const get_staffs = createAction("get_staffs");
export const post_staffs = createAction("post_staffs");
export const put_staffs = createAction("put_staffs");
export const delete_staffs = createAction("delete_staffs");


export const fetchingFalse = createAction("fetchingFalse");
export const loadingFalse = createAction("loadingFalse");
export const loadingDataFalse = createAction("loadingDataFalse");

export const failure_staffs = createAction("failure_staffs");

export const staffs = createReducer(
  {
    [get_staffs]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isFetching = true;
      return newState;
    },

    [post_staffs]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_staffs]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_staffs]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [fetchingFalse]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isFetching = false;
      return newState;
    },
    [loadingDataFalse]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [loadingFalse]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
    isFetching: true,
    staffs: [],
  }
);

export function* flow_get_staffs() {
  while (true) {
    const { payload } = yield take(get_staffs);

    const { data, error } = yield call(
      api_AccessToken,
      `staffs`,
      "get",
      payload.data
    );
    if (!error) {
      yield put(fetchingFalse({ data }));
      !payload.page || payload.page();
    } else {
      yield put(fetchingFalse({ error }));
      !payload.toast || payload.toast(err(error));
    }
  }
}


export function* flow_post_staffs() {
  while (true) {
    const { payload } = yield take(post_staffs);

    const { data, error } = yield call(
      api_AccessToken,
      `staffs/${payload.data.staff_id}`,
      "post",
      payload.data
    );
    if (!error) {
      yield put(loadingFalse({ data }));
      yield put(get_staffs({data:payload.search}));

      !payload.page || payload.page();
    } else {
      yield put(loadingFalse({ error }));
      !payload.toast || payload.toast(err(error));
    }
  }
}

export function* flow_put_staffs() {
  while (true) {
    const { payload } = yield take(put_staffs);

    const { data, error } = yield call(
      api_AccessToken,
      `staffs/${payload.data.staff_id}`,
      "put",
      payload.data
    );
    if (!error) {
      yield put(loadingFalse({ data }));
      yield put(get_staffs({data:payload.search}));

      !payload.page || payload.page();
    } else {
      yield put(loadingFalse({ error }));
      !payload.toast || payload.toast(err(error));
    }
  }
}
export function* flow_delete_staffs() {
  while (true) {
    const { payload } = yield take(delete_staffs);

    const { data, error } = yield call(
      api_AccessToken,
      `staffs/${payload.data.staff_id}`,
      "delete",
      payload.data
    );
    if (!error) {
      yield put(loadingFalse({ data }));
      yield put(get_staffs({data:payload.search}));

      !payload.page || payload.page();
    } else {
      yield put(loadingFalse({ error }));
      !payload.toast || payload.toast(err(error));
    }
  }
}