import staffs from "views_in_page/staffsContainer";
import users_logout from "views_in_page/users_logout";
import entries from "views_in_page/entriesContainer";
import galleries from "views_in_page/galleriesContainer";

const routes = [
  {
    path: "/entries",
    name: "投稿一覧",
    // icon: "fas fa-splotch",
    image:require("assets/img/picture5.png"),
    component: entries,
    layout: "/inside",
    visible: true,
    color:"info"
  },
  // {
  //   path: "/galleries",
  //   name: "galleries",
  //   // icon: "fas fa-splotch",
  //   image:require("assets/img/picture4.png"),
  //   component: galleries,
  //   layout: "/inside",
  //   visible: true,
  //   color:"info",
  //   full:true
  // },
  {
    path: "/staffs",
    name: "スタッフ",
    // icon: "fas fa-splotch",
    image:require("assets/img/staff.png"),
    component: staffs,
    layout: "/inside",
    visible: true,
    color:"info",
    full:true
  },
  {
    path: "/logout",
    name: "ログアウト",
    // icon: "fas fa-key",
    image:require("assets/img/password.png"),
    // icon: "fab fa-expeditedssl",
    component: users_logout,
    layout: "/in",
    visible: true,
  },
];

export default routes;
