import React from "react";
import ReactTable from "react-table";
import moment from "moment/moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import ReactDatetime from "react-datetime";
import defaultImage from "assets/img/image_placeholder.jpg";
import classnames from "classnames";

import validator from "validator";
import TablePagination from "@material-ui/core/TablePagination";
import { stateFromHTML } from "draft-js-import-html";
import {
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import Loader from "views_in_page/Basic/loader";
import { aryOne } from "views_in_page/Basic/util";
import ModalEntries from "views_in_page/Entries/ModalEntries";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import Grid from "./Grid";

export const schparam = (that) => {
  const upd = {
    // month: moment.isMoment(that.state.sch_month)
    //   ? that.state.sch_month.format()
    //   : null,
    // date: moment.isMoment(that.state.sch_date)
    //   ? that.state.sch_date.format()
    //   : null,
    // original_id: that.state.sch_original_id,
    // block_hash: that.state.sch_block_hash,
    // limit: that.state.sch_limit,
    // page: that.state.sch_page + 1,
    // // status: that.state.option_status ? that.state.option_status.value : null,
    // finish: that.state.option_finish ? that.state.option_finish.value : null,
  };
  return upd;
};

export const param = (that) => {
  const content = draftToHtml(
    convertToRaw(that.state.editorState.getCurrentContent())
  );

  const upd = {
    entry_id: that.state.entry_id,
    title: that.state.title,
    content: content,
    post_date: moment.isMoment(that.state.post_date)
      ? that.state.post_date.format()
      : null,
    display_flg: true,
  };
  return upd;
};

class App extends React.Component {
  constructor(props) {
    super(props);

    const m = moment();

    this.state = {
      alert: null,
      // month: moment([m.year(), m.month(), m.date(), 0, 0, 0, 0]),
      // sch_date: moment([m.year(), m.month(), m.date() + 1, 0, 0, 0, 0]),
      modal: false,
      sch_page: 0,
      sch_limit: 10,
      option_finish: { value: 0, label: "unfinished" },
      editorState: EditorState.createEmpty(),
    };

    this.bindRow = this.bindRow.bind(this);
  }

  warningWithConfirmMessage = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Retry？"
          onConfirm={() => this.execute()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Retry"
          cancelBtnText="Cancel"
          showCancel
        >
          it takes a few minutes
        </ReactBSAlert>
      ),
    });
  };

  bindRow = (prop) => {
    // let editorState_first = EditorState.createEmpty();

    // draftjs-to-html works for only the HTML generated by wysiwyg itself.

    let editorState = EditorState.createEmpty();

    if (prop) {
      const html = prop.content;
      const contentBlock = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      editorState = EditorState.createWithContent(contentState);
    }

    // let editorState = stateFromHTML(html);

    this.setState({
      // content: prop ? prop.content : "",
      entry_id: prop ? prop.entry_id : "",
      post_date: prop ? moment(prop.post_date) : "",
      title: prop ? prop.title : "",
      editorState,
    });

    // let editorState_first = EditorState.createEmpty();

    // const html = that.state.content;
    // if (html) {
    // const contentBlock = htmlToDraft(html);
    // const contentState = ContentState.createFromBlockArray(
    //     contentBlock.contentBlocks
    //   );
    //   editorState_first = EditorState.createWithContent(contentState);
    // }
  };

  execute = () => {
    this.props.on_put_entries({
      data: param(this),
      page: () => {
        this.setState({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Completed"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="info"
            >
              Retry Application completed
            </ReactBSAlert>
          ),
        });
      },
      search: schparam(this),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleChangeSearch = () => {
    this.props.on_get_entries({
      data: {
        ...schparam(this),
        sch_page: 0,
      },
    });
    this.setState({ sch_page: 0 });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ sch_page: newPage });
    this.props.on_get_entries({
      data: {
        ...schparam(this),
        page: newPage + 1,
      },
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ sch_limit: parseInt(event.target.value, 10) });
    this.setState({ sch_page: 0 });
    this.props.on_get_entries({
      data: {
        ...schparam(this),
        limit: parseInt(event.target.value, 10),
        page: 0 + 1,
      },
    });
  };

  // componentDidMount() {
  //   this.props.on_get_entries({
  //     data: {
  //       ...schparam(this),
  //     },
  //   });
  // }

  componentDidMount() {
    this.props.on_get_entries({
      data: {
        ...schparam(this),
      },
    });
    document.body.classList.toggle("content-only");
  }
  componentWillUnmount() {
    document.body.classList.toggle("content-only");
  }


  render() {
    const t = this.props.t;

    const entries = this.props.entries.isFetching
      ? []
      : this.props.entries.entries;

    const total_amount = this.props.entries.isFetching
      ? 0
      : this.props.entries.total_amount;

    const count = this.props.entries.isFetching ? 0 : this.props.entries.count;

    const inLoading =
      this.props.entries.isFetching || this.props.entries.isLoading;

    return (
      <>
        <div className="content" style={{marginTop: 0}}>
          <Grid />
        </div>
      </>
    );
    // }
  }
}

export default App;
