/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"p-1 footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <Row>
            {/* <nav className="footer-nav">
              <ul>
                <li>
                  <a href="https://">サイトリンク１</a>
                </li>
                <li>
                  <a href="https://">サイトリンク２</a>
                </li>
                <li>
                  <a href="https://" target="_blank">
                    サイトリンク３
                  </a>
                </li>
              </ul>
            </nav> */}
            <div className="credits ml-auto">
              <span className="copyright">
                &copy; {1900 + new Date().getYear()} 松永興業株式会社, LLC
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
