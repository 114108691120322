import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";

var ps;

const Sidebar = (props) => {
  const [t, i18n] = useTranslation();
  return <Sidebar2 {...props} t={t} />;
};

class Sidebar2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(props.routes),
      activeColor: this.props.activeColor,
    };
  }
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      console.log(prop.image);
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            onClick={(e) => {
              this.setState({ activeColor: prop.color });
            }}
            to={prop.layout + prop.path}
            activeClassName=""
          >
            {prop.image !== undefined ? (
              <>
                <p>
                  {" "}
                  <img
                    alt="..."
                    src={prop.image}
                    // src={require("assets/img/wheel.png")}
                    width="30px"
                  />{" "}
                  {this.props.t(prop.name)}
                </p>
              </>
            ) : (
              <></>
            )}

            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />

                {/* <p>{prop.name}</p> */}
                <p>{this.props.t(prop.name)}</p>
              </>
            ) : (
              <></>
            )}
            {prop.icon === undefined && prop.image === undefined ? (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            ) : (
              <></>
            )}
          </NavLink>
        </li>
        // <></>
      );
    });
  };
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        // data-active-color={this.props.activeColor}
        data-active-color={this.state.activeColor}
      >
        <div className="logo">
          <span>
            <div className="logo-img text-center mt-4 mb-2 ml-0">
              {/* <img src={logo} alt="react-logo" width="120" /> */}
              <img
                // src={require("assets/img/crypto.png")}
                src={require("assets/img/web-site.png")}
                alt="react-logo"
                width="140"
              />
            </div>
          </span>
          <div className="text-center mb-2">
            <span></span>
          </div>
        </div>
        <div className="sidebar-wrapper" ref="sidebar">
          {/* {sessionStorage.getItem("AccessToken") === null ? null : (
            <div className="user">
              <div className="photo">
                <img src={avatar} alt="Avatar" />
              </div>
              <div className="info">
                <a
                  href="..."
                  data-toggle="collapse"
                  aria-expanded={this.state.openAvatar}
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ openAvatar: !this.state.openAvatar });
                  }}
                >
                  <span>
                    Mukumoto Tatsuya
                    <b className="caret" />
                  </span>
                </a>
                <Collapse isOpen={this.state.openAvatar}>
                  <ul className="nav">
                    <li>
                      <NavLink to="/users_info_profile" activeClassName="">
                        <span className="sidebar-mini-icon">MP</span>
                        <span className="sidebar-normal">My Profile</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/users_info_profile" activeClassName="">
                        <span className="sidebar-mini-icon">EP</span>
                        <span className="sidebar-normal">Edit Profile</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/users_info_profile" activeClassName="">
                        <span className="sidebar-mini-icon">S</span>
                        <span className="sidebar-normal">Settings</span>
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </div>
          )} */}

          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
